




































































































































































































import Vue, { PropType } from "vue";

import mixins from "vue-typed-mixins";

import StatusHelpers from "@/mixins/StatusHelpers";
import { ServiceStatus, ServiceStatuses, Issue, KnownLog, Monitor } from "@/api/model";
import api from "@/api";

interface LogEntry {
    id: string | number;
    message: string;
    severity: ServiceStatus;
    source: string;
    metadata: object;
    knownLogs: Array<KnownLog>;
}

interface AutoReload {
    enabled: boolean;
    frequency: number;
    timeoutId: number;
    timestamp: Date;
}

export default mixins(StatusHelpers).extend({
    name: "LogEntry",
    props: {
        logs: {
            type: Array as PropType<Array<LogEntry>>,
            required: false
        }
    },
    data() {
        return {
            showAddKnownLog: {} as boolean[],
            currentlyOpen: -1,
            showMetadata: {},
            showValidationError: false,
            errorText: '',
            validationErrors: {
                status: false,
                notes: false,
                reference: false,
                match: false,
                from: false,
                to: false,
                monitor: false
            },
            knownErrorStatus: null,
            knownLogs: {} as KnownLog[],
            serviceStatuses: [
                {
                    'text': 'Unknown',
                    'value': ServiceStatus.Unknown,
                    'disabled': true
                },
                {
                    'text': 'Okay',
                    'value': ServiceStatus.Okay
                },
                {
                    'text': 'Warning',
                    'value': ServiceStatus.Warning
                },
                {
                    'text': 'Error',
                    'value': ServiceStatus.NotOkay
                }
            ],
            infoSeverity: ServiceStatus.Okay,
            hideFromField: true,
            hideToField: true,
            onlyCurrentMonitor: false,
            logMatches: false,
            originalAutoReload: {} as AutoReload,
            statusRule: [
                function (v: number) { return v > 0 || 'Status is required'; }
            ],
            matchRule: [
                function (v: string) { return (v && v.length > 0) || 'Match is required'; },
                function (v: string) { return (v !== '%') || 'Match cannot only be a wildcard'; }
            ],
            snackbar: false,
            errorTimeout: 2000
        };
    },

    mounted() {
        document.addEventListener("animationend", () => {
            this.showValidationError = false;
        });
    },

    methods: {
        addKnownLog(i: number) {
            let parent = this.findParent();
            if (parent && (parent as any).autoReload.enabled) {
                Object.assign(this.originalAutoReload, (parent as any).autoReload);
                (parent as any).autoReload.enabled = false;
            }

            if (this.knownLogs[i] === undefined) {
                const issue = {} as Issue;
                this.knownLogs[i] = {
                    id: 0,
                    notes: '',
                    status: ServiceStatus.Unknown,
                    issue: issue,
                    match: this.logs[i].message.replace(/\s/g, "_"),
                    from: null,
                    to: null,
                    monitor: null
                };
            }

            this.currentlyOpen = i;
            window.setTimeout(() => {
                (this.$refs.form as any)[0].validate();
                this.setMatchingLog();
            }, 500);
        },

        async processKnownLog(i: number) {
            if (!(this.$refs.form as any)[0].validate()) {
                this.showValidationError = true;
                return;
            }

            if (this.knownLogs[i].match.indexOf("%") > 0) {
                if (!confirm("The log contains a wild card:\n\n" + this.knownLogs[i].match + "\n\nWould you like to proceed?")) {
                    return;
                }
            }
            this.knownLogs[i].monitor = this.onlyCurrentMonitor ? this.$route.params.monitorId : null;

            try {
                let data = await api.monitor.addKnownLog(this.knownLogs[i]);
                if (!data) {
                    throw new Error('Unhandled error');
                } else if (!data.success) {
                    throw new Error(data.errorMessage);
                }
                else {
                    // Refresh logs again
                }
            }
            catch (e) {
                this.showValidationError = true;
                this.errorText = e as string;
                this.snackbar = true;
                console.log(e);
                return;
            }
            this.onCloseKnownLog(i, true);
        },

        onCloseKnownLog(i: number, reloadSoon: boolean) {
            this.showAddKnownLog[i] = false;
            this.currentlyOpen = -1;
            if ((this.originalAutoReload as any).enabled) {
                let parent: any = this.findParent();
                let date = new Date();
                date.setMilliseconds(date.getMilliseconds() + (reloadSoon ? 1000 : this.originalAutoReload.frequency));
                this.originalAutoReload.timestamp = date;
                this.originalAutoReload.timeoutId = window.setTimeout(
                    () => {
                        if (parent.isPageVisible()) {
                            parent.fetchPageData();
                        }
                    },
                    reloadSoon ? 1000 : this.originalAutoReload.frequency
                );
                parent.autoReload = this.originalAutoReload;
                this.originalAutoReload = {
                    enabled: false,
                    frequency: 0,
                    timeoutId: 0,
                    timestamp: new Date()
                };
            } else {
                this.knownLogs = {} as KnownLog[];
            }
        },

        findParent() {
            let parent = this.$parent;

            while (parent && !parent.hasOwnProperty('autoReload')) {
                parent = parent.$parent;
            }

            return parent;
        },

        setMatchingLog() {
            if (this.currentlyOpen >= 0 && this.knownLogs[this.currentlyOpen].match) {
                let input = this.knownLogs[this.currentlyOpen].match.replace(/[\\/^$*+?.()|[\]]/g, '\\$&').replace(/_/g, '.').replace(/%/g, '.*'),
                    regex = new RegExp("(?<!.)" + input + "(?!.)", "ms");
                this.logMatches = regex.test(this.logs[this.currentlyOpen].message);
            }
            else {
                this.logMatches = false;
            }
        }
    }
});
