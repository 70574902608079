import axios from 'axios';
import { ProgressEventHandler, Monitor, ID, MessageDetails, StatusData, ServiceStatus, ServiceStatuses, KnownLog, MessageStatus } from './model';

export type StatusContext = ('monitor' | 'server' | 'client' | 'global');

export interface MonitorApi {
    get(id: ID, offset: number, count: number, statuses: ServiceStatuses, progress?: ProgressEventHandler): Promise<Monitor>;
    getMessage(id: ID, progress?: ProgressEventHandler): Promise<MessageDetails>;
    getStatus(context: StatusContext, id: ID, days?: number, progress?: ProgressEventHandler): Promise<StatusData>;
    addKnownLog(knownLog: KnownLog, progress?: ProgressEventHandler): Promise<MessageStatus>;
    setStatus(id: ID, status: ServiceStatus): Promise<number>;
}

const api: MonitorApi = {
    async get(id: ID, offset: number, count: number, statuses: ServiceStatuses, progress?: ProgressEventHandler): Promise<Monitor> {
        const response = await axios.get(`/api/Monitor/${id}`, {
            params: {
                t: Date.now(),
                offset,
                count,
                statuses
            },
            onDownloadProgress: progress
        }).catch(function (error) {
            if (error.response) {
                throw error.response.data;
            } else if (error.request) {
                throw error.request;
            } else {
                throw error.message;
            }
        });

        let raw = response.data;
        let data = raw.monitor;
        data.messages = raw.messages;
        data.headers = raw.headers;
        if (raw.options) {
            Object.assign(data, raw.options);
        }
        return data;
    },
    async getMessage(id: ID, progress?: ProgressEventHandler): Promise<MessageDetails> {
        const response = await axios.get(`/api/Monitor/Message/${id}`, {
            params: {
                t: Date.now()
            },
            onDownloadProgress: progress
        }).catch(function (error) {
            if (error.response) {
                throw error.response.data;
            } else if (error.request) {
                throw error.request;
            } else {
                throw error.message;
            }
        });

        return response.data;
    },
    async getStatus(context: StatusContext, id: ID, days?: number, progress?: ProgressEventHandler): Promise<StatusData> {
        const response = await axios.get(`/api/Monitor/Status/${context}/${id}`, {
            params: {
                t: Date.now(),
                days
            },
            onDownloadProgress: progress
        }).catch(function (error) {
            if (error.response) {
                throw error.response.data;
            } else if (error.request) {
                throw error.request;
            } else {
                throw error.message;
            }
        });

        return response.data;
    },
    async addKnownLog(knownLog: KnownLog, progress?: ProgressEventHandler): Promise<MessageStatus> {
        const response = await axios.post(`/api/Log/Add`,
            knownLog,
            { onDownloadProgress: progress }
        ).catch(function (error) {
            if (error.response) {
                throw error.response.data;
            } else if (error.request) {
                throw error.request;
            } else {
                throw error.message;
            }
        });

        return response.data;
    },
    async setStatus(id: ID, status: ServiceStatus) {
        const response = await axios.post(`/api/Monitor/Status/${id}/${status}`)
            .catch(function (error) {
                if (error.response) {
                    throw error.response.data;
                } else if (error.request) {
                    throw error.request;
                } else {
                    throw error.message;
                }
            });

        return response.status;
    }
};

export default api;