export type ProgressEventHandler = (progressEvent: ProgressEvent) => void;

export type ID = string | number;

export enum ServiceStatus {
    Unknown = 0,
    Okay = 1,
    Warning = 2,
    NotOkay = 3
}

export enum ServiceStatuses {
    Unknown = 1 << ServiceStatus.Unknown,
    Okay = 1 << ServiceStatus.Okay,
    Warning = 1 << ServiceStatus.Warning,
    NotOkay = 1 << ServiceStatus.NotOkay,

    All = Unknown | Okay | Warning | NotOkay,
    None = 0
}

export interface DataHeader {
    text: string
    value: string
    align?: 'start' | 'center' | 'end',
    omitIfNull: boolean,
    width?: number | string,
    component?: string,
    componentProp?: string,
    componentSlot?: string,
    extraComponentProps?: { [name: string]: any },
    [otherProp: string]: any
}

export interface Group {
    id: ID,
    name: string,
    status: ServiceStatus,
    services?: Service[]
}

export interface Service {
    id: ID,
    name: string,
    status: ServiceStatus,
    group?: Group,
    monitors?: Monitor[]
}

export interface Monitor {
    id: ID,
    name: string,
    type: string,
    status: ServiceStatus,
    timestamp: string,
    runningOn?: string,
    service?: Service,
    group?: Group,
    messages?: Message[]
    messageCount?: number,
    headers?: DataHeader[],
    tableWidth?: number
}

export interface Message {
    id: ID,
    status?: ServiceStatus,
    timestamp: string,
    details?: MessageDetails
}

export interface MessageDetails {
    name: string,
    serviceTimestamp: string,
    loggedTimestamp: string,
    path: string[],
    headers?: DataHeader[],
    specificDetails?: any
}

export interface StatusCounts {
    date: string,
    okay: number,
    warning: number,
    notOkay: number,
    unknown: number
}

export interface StatusData {
    data: StatusCounts[],
    anyOkay: boolean,
    anyWarning: boolean,
    anyNotOkay: boolean,
    anyUnknown: boolean
}

export interface Issue {
    reference: string;
    notes: string;
    status: string;
    title: string;
    url: string;
}

export interface KnownLog {
    id: number;
    notes: string;
    status: ServiceStatus;
    issue: Issue;
    match: string;
    from: Date | null;
    to: Date | null;
    monitor: ID | null;
}

export interface MessageStatus {
    success: boolean;
    errorMessage: string;
}